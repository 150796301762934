import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql, Link }  from 'gatsby'
import ScrollAnim from 'rc-scroll-anim'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div'
import H4 from '../components/H4'
import H5 from '../components/H5'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'

import { 
  styledCloudServer,
  header,
  scrollTo
} from '../components/styles/Contrato.styles'

export const PageTemplate = ({
  data,
  ...rest
}) => {
  return (
    <Fragment>
      <section css={styledCloudServer}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>Política de Segurança e Privacidade da Informação e Serviços</H4>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row']}
            pt={40}
            pb={40}
          >
            <Column
              mb={[30, 30, 0, 0]}
            >
              <Div
                css={scrollTo}
              >
                <H5>Política de Segurança e Privacidade da Informação e Serviços</H5>
                <ScrollAnim.Link
                  to='proposito'
                  offsetTop='120'
                >
                  Propósito
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='aplicacao'
                  offsetTop='120'
                >
                  Aplicação
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='controles'
                  offsetTop='120'
                >
                  Controles
                </ScrollAnim.Link>
                <ScrollAnim.Link
                  to='responsabilidades'
                  offsetTop='120'
                >
                  Responsabilidades
                </ScrollAnim.Link>
                <Link
                  to='/contrato'
                >
                  Voltar para Contrato
                </Link>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', '100%', 422]}
              >
                <Div id='proposito'>
                  <H6>1. Propósito</H6>
                  <ul>
                    <li>&#9679; Criar uma cultura de conscientização e proteção da privacidade das informações confidenciais, restritas e dados pessoais em nossas operações, sistemas de informação e atividades de trabalho diárias.</li>
                    <li>&#9679; Estabelecer um compromisso sob o qual todas as pessoas que trabalham para e com a HostDime Brasil, entendem quais informações precisam ser protegidas e como manter sua Confidencialidade, Integridade, Disponibilidade e Privacidade.</li>
                    <li>&#9679; Descrever procedimentos autorizados para lidar com informações confidenciais, restritas e dados pessoais, usando controles administrativos, físicos e técnicos apropriados.</li>
                    <li>&#9679; Descrever as consequências das decisões e ações que não estão em conformidade com esta política.</li>
                    <li>&#9679; Definir a aplicação da avaliação de riscos para identificar e tratar os riscos à segurança e privacidade da informação.</li>
                    <li>&#9679; Garantir o atendimento adequado às regulamentações vigentes aplicados ao negócio.</li>
                    <li>&#9679; Desenvolver relações de confiança com clientes e outros, com base na conformidade com leis e regulamentos aplicáveis, assim como nos requisitos contratuais.</li>
                    <li>&#9679; Fazer cumprir as metas destinadas aos objetivos de segurança e privacidade da informação e serviços, definidos pela organização.</li>
                    <li>&#9679; Estabelecer um compromisso com a melhoria contínua do sistema de gestão integrado e dos serviços.</li>
                  </ul>
                </Div>
                <Div id='aplicacao'>
                  <H6 style={{ marginTop: 24 }}>2. Aplicação</H6>
                  <Paragraph>Esta política inclui todas as instâncias de processamento de informações confidenciais, restritas e dados pessoais, descritas na Política de Segurança e Privacidade da Informação e Serviços, incluindo todas as formas de informações proprietárias de negócio, informações pessoais não públicas do cliente e informações pessoais de funcionários, pela HostDime Brasil, seus proprietários, pessoal e agentes.</Paragraph>
                </Div>
                <Div id='controles'>
                  <H6 style={{ marginTop: 24 }}>3. Controles</H6>
                  <Paragraph>Esta política inclui todas as instâncias de processamento de informações confidenciais, restritas e dados pessoais, descritas na Política de Segurança e Privacidade da Informação e Serviços, incluindo todas as formas de informações proprietárias de negócio, informações pessoais não públicas do cliente e informações pessoais de funcionários, pela HostDime Brasil, seus proprietários, pessoal e agentes.</Paragraph>
                </Div>
                <Div id='responsabilidades'>
                  <H6 style={{ marginTop: 24 }}>4. Responsabilidades</H6>
                  <Paragraph>Demais informações acerca desta Política de Segurança e Privacidade da Informação e Serviços, devem ser solicitadas através do email <a href='certificacoes@hostdime.com.br'>certificacoes@hostdime.com.br</a>, e que serão apresentadas por um representante da HostDime Brasil.</Paragraph>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object,
  values: PropTypes.object
}

const StaticPage = (props) => {
  return (
    <Layout>
      <Seo title='Política de Segurança da Informação e Serviço' />
      <PageTemplate
        title='Política de Segurança da Informação e Serviço'
        {...props}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "cloud-server/cloud-server.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default (StaticPage)
